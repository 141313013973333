<template>
  <div
    :data-block-id="id"
    :data-block-type="__typename"
    class="relative items-center justify-center"
    :class="[containerClassList, maxWidthClassList]"
  >
    <Button
      :size="BUTTON_SIZE.BASE"
      class="absolute z-10 !rounded-full uppercase"
      :truncate="false"
      :title="t('btn.playTrailer')"
      :icon="SVG_ICON.PLAY"
      :text="t('btn.playTrailer')"
      @click="isTrailerModalVisible = true"
    />

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="remoteVideoId ?? ''"
      :autoplay="autoplay ?? true"
      :controls="controls ?? true"
      :mute="mute ?? false"
      @close="isTrailerModalVisible = false"
    />

    <ImageWrapper
      :image="imageObject"
      :config="imageConfig"
      :classes="{
        image: imageClassList,
        container: 'relative',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { CMS_IMAGE_BLOCK_RATIO } from '@cms/constants/cms'
import { VIDEO_PROVIDER } from '@base/constants/video'
import { IMAGE_FIT } from '@base/constants/image'
import { BUTTON_SIZE } from '@base/constants/button'
import { SVG_ICON } from '@assets/constants/inline-svg'
import type {
  CmsImageBlockRatio,
  CmsImageBlockSize,
  CmsImageBlockAlignment,
  CmsImageBlockClip,
} from '@cms/types/cms'
import getImage from '@base/utils/get-image'
import useImageClasses from '@base/composables/use-image-classes'
import type { Image } from '#gql/default'

export interface CmsBlockVideoProps {
  id: string
  __typename: 'CmsVideoBlock'
  image: Image
  ratio: CmsImageBlockRatio
  size: CmsImageBlockSize
  loading: ImageLoading
  renderAboveTheFold: boolean
  preload: boolean
  alignment?: CmsImageBlockAlignment
  clip?: CmsImageBlockClip
  videoProvider: VideoProvider
  videoUrl: string
  autoplay?: boolean
  mute?: boolean
  controls?: boolean
}

const props = defineProps<CmsBlockVideoProps>()

const isTrailerModalVisible = ref(false)

const imageObject = computed(() => getImage(props, 'image', ''))

const remoteVideoId = computed(() =>
  props.videoProvider === VIDEO_PROVIDER.YOUTUBE
    ? getYouTubeVideoId(props.videoUrl)
    : null
)

function getYouTubeVideoId(url: string): string {
  let videoId = ''
  const urlParts = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

  if (urlParts[2] !== undefined) {
    const idParts: string[] = urlParts[2].split(/[^0-9a-z_\-]/i)
    videoId = idParts[0]
  } else {
    videoId = url
  }

  return videoId
}

const config = useAppConfig()

const width = computed(() => ({
  thumbnail: 150,
  sm: 240,
  md: 340,
  lg: 768,
  boxed: imageObject.value.width,
  original: imageObject.value.width,
}))

const imageConfig = computed(() => {
  return {
    ...(config.images as ImageConfigs).cms.imageBlock,
    fit:
      props.ratio === CMS_IMAGE_BLOCK_RATIO.ORIGINAL
        ? IMAGE_FIT.CROP
        : IMAGE_FIT.STRETCH,
    height: undefined,
    width: width.value[props.size],
    loading: props.loading,
    preload: props.preload,
  }
})

const { t } = useI18n()

const { maxWidthClassList, containerClassList, imageClassList } =
  useImageClasses({
    size: props.size,
    ratio: props.ratio,
    alignment: props.alignment,
    clip: props.clip,
  })

defineOptions({
  name: 'CmsBlockVideo',
})
</script>

<i18n>
de:
  btn:
    playTrailer: "Trailer ansehen"
es:
  btn:
    playTrailer: "Ver tráiler"
</i18n>
